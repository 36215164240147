<template>
  <card
    :content="content"
    :schema="getSchemaHatchery"
    :form-data="getDataHatchery"
    :editable="getEditableHatchery"
    @update-form="updateHatchery"
    @submitAction="addHatchery"
  />
</template>

<script>
import Card from "../components/Card";
import confHatchery from "../conf/confHatchery";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Hatchery",
  components: { Card },
  title() {
    const locationName =
      this.$store.getters["company/get_locationName"] ??
      localStorage.getItem("locationName");
    return this.$t(this.$route.meta.title) + " – " + locationName;
  },

  data() {
    return {
      content: confHatchery.content,
      hidden: true
    };
  },
  created() {
    this.$store.dispatch("hatchery/loadHatchery");
  },
  computed: {
    ...mapGetters("hatchery", [
      "getDataHatchery",
      "getSchemaHatchery",
      "getEditableHatchery"
    ])
  },
  methods: {
    ...mapActions("hatchery", ["updateHatchery", "addHatchery", "loadHatchery"])
  }
};
</script>

<style scoped></style>
